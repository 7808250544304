import styled from "styled-components"

import { fontStyle } from "@styles/fonts"

export const FormStyled = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .label {
    p {
      ${fontStyle("small", "bold")};
      display: block;
      color: ${({ theme }) => theme.palette.common.black};
    }
    /* margin-top: 28px; */

    &:not(.date):not(.time):not(.html) p {
      margin-bottom: 8px;
      margin-top: 28px;
    }

    &.html {
      p {
        margin-top: 10px;
        margin-bottom: 10px;
      }

      &:first-child p {
        margin-top: 20px;
      }

      &:last-child p {
        margin-bottom: 0;
      }
    }

    &.telephone p > * {
      white-space: nowrap;
    }

    &.error p {
      color: ${({ theme }) => theme.palette.common.red};
    }

    &.checkbox {
      display: inline-block;
      order: 2;
    }
  }

  .field {
    display: flex;
    flex-basis: 100%;

    &.half {
      @media (min-width: ${({ theme }) => theme.breakpoints.tabletVertical}) {
        flex-basis: 50%;
        &:not(.date) {
          padding-right: 15px;
        }

        &:nth-child(odd) + .half {
          padding-right: 0px;
        }
      }
    }

    &.third {
      @media (min-width: ${({ theme }) => theme.breakpoints.tabletVertical}) {
        flex-basis: 33%;

        & + .third {
          padding-right: 0px;
        }
      }
    }

    &.quarter {
      @media (min-width: ${({ theme }) => theme.breakpoints.tabletVertical}) {
        flex-basis: 25%;
        padding-right: 15px;

        & + .quarter {
          padding-right: 0px;
        }
      }
    }

    &.inline {
      flex: auto;
      flex-basis: auto;
    }

    &:first-child:not(.half) {
      label {
        margin-top: 0px;
      }
    }
  }

  .numbers-flags {
    .Select__value-container > div:first-child {
      display: flex;
      flex-direction: row-reverse;
      gap: 5px;
    }

    .Select__menu > div > div {
      display: flex;
      justify-content: flex-end;
      flex-direction: row-reverse;
      gap: 7px;
    }
  }
`

export const SpinnerContainer = styled.div`
  ${({ fullLoaderSize }) =>
    fullLoaderSize !== "small" &&
    `
  min-height: 480px;
  padding-top: calc(480px / 4);
`}
`

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import { ToastProvider } from "react-toast-notifications"

import classnames from "classnames"
import deepEqual from "deep-equal"
import { Field, Formik } from "formik"
import * as Yup from "yup"

import Button from "@atoms/button"
import ErrorMsg from "@atoms/error"
import Input from "@atoms/input"
import Spinner from "@atoms/spinner"
import ToastAtom from "@atoms/toast"

import {
  emailRegex,
  passwordStrengthHigh,
  passwordStrengthMedium,
  phoneRegex,
  otpRegex,
} from "@constants/regexs"

import usePrevious from "@utils/hooks/usePrevious"

import Component from "@reach/component-component"

import { FormStyled, SpinnerContainer } from "./form.styled"

const Form = forwardRef(
  (
    {
      initialValues = {},
      shouldUpdateOnInitialValues = false,
      fullLoading,
      fields = [],
      children,
      loading,
      submitText,
      variant,
      error,
      style,
      inline,
      fullLoaderSize = null,
      fullLoaderOver = false,
      classFields,
      styleFields,
      onChange,
      onSubmit,
      disabled = () => null,
    },
    ref
  ) => {
    const { t } = useTranslation()
    const prevInitialValues = usePrevious(initialValues)
    const firstValues = {}

    const [afterSubmit, setAfterSubmit] = useState(false)
    const [strength, setStrength] = useState(null)
    const [shownForm, setShownForm] = useState(true)

    const texts = t("forms", { returnObjects: true })
    const textsStrength = {
      low: t("forms.password.validation.password_strength_low", {
        returnObjects: true,
      }),
      medium: t("forms.password.validation.password_strength_medium", {
        returnObjects: true,
      }),
      high: t("forms.password.validation.password_strength_high", {
        returnObjects: true,
      }),
    }

    const pwdStrengthColors = {
      high: "#0063FF",
      medium: "#DEA02C",
      low: "#E02020",
    }
    const pwdStrengthColor = pwdStrengthColors[strength]
    const pwdStrengthStyle = {
      color: pwdStrengthColor,
      fontWeight: "bold",
      fontStyle: "italic",
      fontSize: 16,
      marginBottom: -14,
    }

    const validationObj = {}

    fields.map(f => {
      const { name, type, required } = f
      const fieldTexts = texts[name] || {}
      let value = null

      if (name === "email") {
        value = Yup.string()
          .required(t("required"))
          .email(fieldTexts.validation)
          .matches(emailRegex, fieldTexts.validation)
      } else if (name === "password") {
        value = Yup.string()
          .required(t("required"))
          .min(8, texts.password.validation.min)
          .max(30, texts.password.validation.max)
      } else if (name === "phone") {
        value = Yup.string()
          .required(t("required"))
          .min(8, texts.phone.validation)
          .max(20, texts.phone.validation)
          .matches(phoneRegex, texts.phone.validation)
      } else if (name === "otp") {
        value = Yup.string()
          .required(t("required"))
          .length(6, texts.otp.validation.length)
          .matches(otpRegex, texts.otp.validation.accepted_chars)
      } else if (required) {
        if (type === "select") {
          value = Yup.object().shape({
            value: Yup.string().required(t("required")),
          })
        } else if (type === "checkbox") {
          value = Yup.boolean(t("required")).oneOf([true], t("required"))
        } else {
          value = Yup.string().required(fieldTexts.validation || t("required"))
        }
      }

      validationObj[name] = value
      firstValues[name] =
        type === "checkbox"
          ? initialValues[name] || false
          : initialValues[name] || ""
    })

    const validationSchema = Yup.object().shape(validationObj)

    const submit = formikProps => {
      const { validateForm, values } = formikProps

      validateForm().then(err => {
        const errors = Object.keys(err)
        setAfterSubmit(true)
        if (errors.length) return

        onSubmit(values)
      })
    }

    const isBooleanDisabled = typeof disabled === "boolean"

    useEffect(() => {
      if (
        deepEqual(prevInitialValues, initialValues) ||
        !shouldUpdateOnInitialValues
      )
        return
      // rerender form
      setShownForm(false)
      setTimeout(() => setShownForm(true), 10)
    }, [initialValues])

    return fullLoading ? (
      <SpinnerContainer fullLoaderSize={fullLoaderSize}>
        <Spinner over={fullLoaderOver} />
      </SpinnerContainer>
    ) : (
      shownForm && (
        <Formik
          initialValues={firstValues}
          validationSchema={validationSchema}
          validateOnChange={afterSubmit}
          validateOnBlur={afterSubmit}
        >
          {formikProps => {
            const { values, setFieldValue, errors } = formikProps

            useImperativeHandle(ref, () => {
              return {
                setFieldValue,
              }
            })

            const onKeyDownInput = ({ keyCode } = {}) => {
              if (keyCode && keyCode === 13) {
                // get all required fields
                const requireds = fields
                  .filter(({ required }) => required)
                  .map(({ name }) => name)

                // check if all required fields have values
                const keys = Object.keys(values)
                const vals = Object.values(values)
                const filled = keys.every((k, i) => {
                  const v = vals[i]
                  const required = requireds.indexOf(k) > -1
                  return required ? Boolean(v) : true
                })
                // if everything required is filled, trigger submit when pressing enter
                if (filled) submit(formikProps)
              }
            }

            return (
              <FormStyled style={style}>
                <Component
                  values={values}
                  didUpdate={({ prevProps, props }) => {
                    if (!deepEqual(prevProps, props)) {
                      let { values = {} } = props
                      // clean nameless inputs (html, etc...)
                      Object.keys(values).map((v, i) => {
                        if (v === "undefined") delete values[v]
                        return true
                      })
                      onChange && onChange(values)
                    }
                  }}
                />

                <ToastProvider
                  autoDismiss
                  autoDismissTimeout={3000}
                  components={{ Toast: ToastAtom }}
                  placement="bottom-left"
                >
                  {fields && fields.length
                    ? fields.map(f => {
                        const {
                          name,
                          type = "text",
                          component = null,
                          hasBorder = true,
                          withStrength,
                          half,
                          third,
                          quarter,
                          labelStyle = null,
                          className,
                          classNameInput,
                          style,
                          onChangeInput,
                          ...otherFieldProps
                        } = f
                        const fieldTexts = texts[name] || {}
                        const Children = f.html

                        return type === "html" ? (
                          <Children key={`html-${name}`} />
                        ) : (
                          <div
                            key={name}
                            className={classnames(
                              "field",
                              {
                                inline,
                                half,
                                third,
                                quarter,
                              },
                              className
                            )}
                            style={style}
                          >
                            <Field
                              as={component || Input}
                              name={name}
                              type={type}
                              className={classNameInput}
                              hasBorder={hasBorder}
                              label={fieldTexts.label}
                              placeholder={fieldTexts.placeholder}
                              error={
                                (errors[name] || {}).value ||
                                errors[name] ||
                                (withStrength ? textsStrength[strength] : null)
                              }
                              errorStyle={
                                withStrength
                                  ? name === "password" && errors[name]
                                    ? {}
                                    : pwdStrengthStyle
                                  : {}
                              }
                              labelStyle={labelStyle}
                              onKeyDownInput={onKeyDownInput}
                              onChangeInput={value => {
                                onChangeInput && onChangeInput(value)
                                if (withStrength) {
                                  let strength = "low"
                                  let regExp = new RegExp(passwordStrengthHigh)
                                  let matched = regExp.test(value)
                                  if (matched) {
                                    strength = "high"
                                  } else {
                                    regExp = new RegExp(passwordStrengthMedium)
                                    matched = regExp.test(value)
                                    if (matched) {
                                      strength = "medium"
                                    }
                                  }
                                  setStrength(strength)
                                }
                              }}
                              {...otherFieldProps}
                            />
                          </div>
                        )
                      })
                    : null}
                  {children}

                  {submitText && (
                    <div className={"footer"}>
                      <Button
                        className={"button"}
                        loading={loading}
                        disabled={
                          loading || isBooleanDisabled
                            ? disabled
                            : disabled && disabled(values)
                        }
                        text={submitText}
                        variant={variant}
                        onClick={() => submit(formikProps)}
                      />
                    </div>
                  )}

                  <ErrorMsg name={error} />
                </ToastProvider>
              </FormStyled>
            )
          }}
        </Formik>
      )
    )
  }
)

export default Form
